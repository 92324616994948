<template>
  <b-row>
    <b-col
      v-if="$can('create', 'group')"
      cols="12"
    >
      <b-button
        class="mb-1"
        variant="primary"
        @click="addGroupModal = true"
      >Добавить</b-button>
      <b-button
        class="mb-1 ml-1"
        variant="secondary"
        @click="hideArchived = !hideArchived"
      >{{ hideArchived ? "Показать архивные" : "Скрыть архивные" }}</b-button>
    </b-col>
    <b-col cols="12">
      <b-table
        :fields="[
          { key: 'name', label: 'Наименование' },
          { key: 'year', label: 'Год' },
          { key: 'actions', label: 'Действия' },
        ]"
        :items="groups"
      >
        <template #cell(actions)="row">
          <b-icon-trash
            variant="danger"
            class="cursor-pointer"
            @click="deleteGroup(row.item.id)"
          />
          <b-icon-pencil-fill
            variant="danger"
            class="cursor-pointer"
            @click="editGroup(row.item)"
          />
        </template>
      </b-table>
    </b-col>
    <b-modal
      v-model="addGroupModal"
      title="Добавить группу"
    >
      <b-form-group label="Наименование">
        <b-form-input v-model="groupName" />
      </b-form-group>
      <b-form-group label="Год выпуска">
        <b-form-input v-model="year" />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="archived"
          name="archived"
        >Архивная</b-form-checkbox>
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="outline-danger"
          @click="addGroupModal = false"
        >Отмена</b-button>
        <b-button
          variant="success"
          @click="saveData"
        >Сохранить</b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BTable,
  BModal,
  BFormGroup,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormCheckbox,
  BIconTrash,
  BIconPencilFill
} from "bootstrap-vue"

export default {
  name: "ListGroups",
  components: {
    BTable,
    BModal,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BIconTrash,
    BIconPencilFill,
    BFormCheckbox,
  },
  computed: {
    groups() {
      let ret = this.$store.state.info.childrenGroups
      if (this.hideArchived) {
        ret = ret.filter(i => !i.archived)
      }
      return ret
    }
  },
  data() {
    return {
      addGroupModal: false,
      id: null,
      groupName: "",
      year: 0,
      archived: false,
      hideArchived: true,
    }
  },
  methods: {
    deleteGroup(id) {
      this.$store
        .dispatch("info/deleteGroup", id)
        .then(() => {
          this.$toast.success("Группа удалена")
        })
        .catch(() => {
          this.$toast.error("Не удалось удалить группу")
        })
    },
    fetchData() {
      this.$store.dispatch("info/getChildrenGroups")
    },
    saveData() {
      if (this.id) {
        this.$store
          .dispatch("info/updateGroup", {
            id: this.id,
            name: this.groupName,
            year: this.year,
            archived: this.archived,
          })
          .then(() => {
            this.groupName = ""
            this.addGroupModal = false
            this.$toast.success("Группа изменена")
            this.$store.dispatch("info/getChildrenGroups")
          })
          .catch(() => {
            this.$toast.error("Не удалось изменить группу")
          })
      } else {
        this.$store
          .dispatch("info/addGroup", {
            name: this.groupName,
          })
          .then(() => {
            this.groupName = ""
            this.addGroupModal = false
            this.$toast.success("Группа добавлена")
          })
          .catch(() => {
            this.$toast.error("Не удалось добавить группу")
          })
      }
    },
    editGroup(item) {
      this.id = item.id
      this.groupName = item.name
      this.year = item.year
      this.archived = item.archived
      this.addGroupModal = true
    },
  },
  created() {
    this.fetchData()
  },
}
</script>

<style scoped>
</style>
